import { css } from "styled-components";
import styled from "styled-components";

export const SIDE_SPACING = 3.5;

interface MaxWidthProps {
  disableOnWidth?: number;
}
const MaxWidth = styled.div<MaxWidthProps>`
  width: ${100 - SIDE_SPACING * 2}%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;

  ${({ disableOnWidth }) =>
    disableOnWidth
      ? css`
          @media (max-width: ${disableOnWidth}px) {
            width: 100%;
          }
        `
      : undefined}
`;

export default MaxWidth;
